<template>
    <div>
        <h4 class="mb-2">EPG Provider mappings</h4>

        <b-button variant="primary" @click="addModalActive = true">
            <feather-icon
                icon="PlusIcon"
                class="mr-50"
            />
            <span class="align-middle">Add</span>
        </b-button>

        <basic-table :columns="columns" :data="mappings" v-slot="props">
            <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                <b-button variant="danger" @click="removeMapping(props.row.channel_id)" class="mr-1">
                    <feather-icon
                        icon="Trash2Icon"
                    />
                </b-button>
            </div>
            <span v-else>
                {{ props.formattedRow[props.column.field] }}
            </span>
        </basic-table>

        <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label>Provider EPG ID:</label>
                    <b-form-input
                        type="text"
                        placeholder="Name"
                        v-model="addObject.provider_channel_id"
                    />
                </b-form-group>
                <b-form-group>
                    <label>Max days to download (0 = Unlimited):</label>
                    <b-form-input
                        type="text"
                        placeholder="Max days"
                        v-model="addObject.max_days"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Channel:</label>
                    <v-select
                        v-model="addObject.channel_id"
                        :reduce="type => type.id"
                        label="name"
                        :options="channels"
                    />

                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addMapping">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import BasicTable from '@/views/components/BasicTable'
    import { BButton, BFormGroup, BFormInput, BModal } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BButton,
            BModal,
            BFormInput,
            BFormGroup,
            vSelect,
            BasicTable
        },
        props: {
            epgProviderId: {
                type: Text,
                required: true
            }
        },
        data() {
            return {

                dataLoaded: false,

                mappings: [],
                channels: [],

                columns: [
                    {
                        label: 'Provider EPG ID',
                        displayType: 0,
                        field: 'provider_channel_id',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Channel name',
                        displayType: 0,
                        field: this.channelRenderer,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: 'Max Days (0 = Unlimited)',
                        displayType: 0,
                        field: 'max_days',
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: '',
                        displayType: 1,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addObject: {},
                addModalActive: false
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/epg_provider/${  this.epgProviderId  }/mapping`)
                loadPromise.then(function(response) {
                    thisIns.mappings = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                const channelPromise = this.$http.get('/api/management/v1/tv_channel/')
                channelPromise.then(function(response) {
                    thisIns.channels = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise, channelPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addMapping() {
                this.dataLoaded = false
                const thisIns = this

                const addPromise = this.$http.post(`/api/management/v1/epg_provider/${  this.epgProviderId  }/mapping`, {
                    provider_channel_id: this.addObject.provider_channel_id,
                    channel_id: this.addObject.channel_id,
                    max_days: Number(this.addObject.max_days)
                })

                addPromise.then(function() {
                    thisIns.addModalActive = false
                    thisIns.addObject.provider_channel_id = ''
                    thisIns.addObject.channel_id = 0
                    thisIns.$printSuccess('EPG Provider mapping added')
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeMapping(id) {
                this.dataLoaded = false

                const thisIns = this
                const removePromise = this.$http.delete(`/api/management/v1/epg_provider/${  this.epgProviderId  }/mapping/${  id}`)
                removePromise.then(function() {
                    thisIns.$printSuccess('EPG Provider mapping removed')
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            channelRenderer(rowObj) {
                const channel = this.channels.find(channel => channel.id === rowObj.channel_id)
                return (channel) ? channel.name : 'Unknown'
            }
        },
        computed: {
            channelsMap() {
                if (!this.channels) return {}
                const channels = {}
                this.channels.forEach(function(channel) {
                    channels[channel.id] = channel
                })

                return channels
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>