<template>
    <div>
        <b-card>
            <b-row>
                <b-col md="12" lg="4" class="mb-3">
                    <b-overlay :show="!dataLoaded">
                        <div v-if="!editEnabled">

                            <table>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Name</span>
                                    </th>
                                    <td class="pb-50">
                                        {{ epgProvider.name }}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">URL</span>
                                    </th>
                                    <td class="pb-50">
                                        {{ epgProvider.url }}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">EPG Type</span>
                                    </th>
                                    <td class="pb-50">
                                        {{ [{id: 0, name: 'XML File'}, {id: 1, name: 'GZipped XML File'}, {id: 2, name: 'XML in tar.gz'}, {id: 3, name: 'XML in zip'}][epgProvider.epg_type].name }}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Update interval</span>
                                    </th>
                                    <td class="pb-50">
                                        {{ epgProvider.update_interval }} minutes
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pb-50">
                                        <span class="font-weight-bold mr-2">Last update</span>
                                    </th>
                                    <td class="pb-50" v-if="!epgProvider.update_in_progress">
                                        {{ moment(epgProvider.last_update).format('YYYY/MM/DD HH:mm:ss') }}
                                    </td>
                                    <td class="pb-50" v-else>
                                        <b-badge variant="primary">
                                            Update in progress
                                        </b-badge>
                                    </td>
                                </tr>
                            </table>

                        </div>
                        <div v-else>
                            <b-form-group>
                                <label>Name:</label>
                                <b-form-input
                                    type="text"
                                    placeholder="Name"
                                    v-model="editObject.name"
                                />
                            </b-form-group>
                            <b-form-group>
                                <label>URL:</label>
                                <b-form-input
                                    type="text"
                                    placeholder="URL"
                                    v-model="editObject.url"
                                />
                            </b-form-group>
                            <b-form-group>
                                <label>Update interval:</label>
                                <b-form-input
                                    type="text"
                                    placeholder="Update interval"
                                    v-model="editObject.update_interval"
                                />
                            </b-form-group>

                            <b-form-group>
                                <label>Type:</label>
                                <v-select
                                    v-model="editObject.epg_type"
                                    :reduce="type => type.id"
                                    label="name"
                                    :options="[{id: 0, name: 'XML File'}, {id: 1, name: 'GZipped XML File'}, {id: 2, name: 'XML in tar.gz'}, {id: 3, name: 'XML in zip'}]"
                                />

                            </b-form-group>
                        </div>

                        <hr/>

                        <b-button v-if="!editEnabled" variant="warning" @click="editEnabled = true" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Edit</span>
                        </b-button>

                        <b-button v-if="!editEnabled" variant="primary" @click="refreshProvider" class="mr-1">
                            <feather-icon
                                icon="RefreshCwIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Refresh provider</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="success" @click="saveProvider" class="mr-1">
                            <feather-icon
                                icon="SaveIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Save</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="editEnabled = false">
                            <feather-icon
                                icon="XIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Cancel</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="removeProvider" class="float-right">
                            <feather-icon
                                icon="Trash2Icon"
                                class="mr-50"
                            />
                            <span class="align-middle">Remove</span>
                        </b-button>

                    </b-overlay>
                </b-col>
                <b-col md="12" lg="8" class="mb-3">
                    <EPGProviderMappings :epg-provider-id="$route.params.id"/>
                </b-col>
            </b-row>
        </b-card>

        <EPGProviderChannels :url="'/api/management/v1/epg_provider/' + $route.params.id + '/channels'"/>
    </div>
</template>
<script>

    import { BBadge, BButton, BCard, BCol, BFormGroup, BFormInput, BOverlay, BRow } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import EPGProviderMappings from '@/views/TV/EPG/EPGProviderMappings'
    import EPGProviderChannels from '@/views/TV/EPG/EPGProviderChannels'

    export default {
        components: {
            EPGProviderChannels,
            EPGProviderMappings,
            BOverlay,
            BCard,
            BButton,
            BRow,
            BCol,
            BFormInput,
            BFormGroup,
            BBadge,
            vSelect
        },
        data() {
            return {

                epgProvider: {

                },

                dataLoaded: false,

                editEnabled: false,
                editObject: {}

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false

                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/epg_provider/${  this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.epgProvider = response.data
                    thisIns.editObject = JSON.parse(JSON.stringify(response.data))
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveProvider() {
                this.dataLoaded = false

                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/epg_provider/${  this.$route.params.id}`, {
                    name: this.editObject.name,
                    url: this.editObject.url,
                    epg_type: Number(this.editObject.epg_type),
                    update_interval: Number(this.editObject.update_interval)
                })

                savePromise.then(function() {
                    thisIns.$printSuccess('EPG Provider saved')
                    thisIns.editEnabled = false
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            removeProvider() {

                this.dataLoaded = false

                const thisIns = this
                const removePromise = this.$http.delete(`/api/management/v1/epg_provider/${  this.$route.params.id}`)
                removePromise.then(function() {
                    thisIns.$printSuccess('EPG Provider removed')
                    thisIns.$nextTick(() => {
                        thisIns.$router.push('/epg_providers')
                    })
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            refreshProvider() {
                this.dataLoaded = false

                const thisIns = this
                const refreshPromise = this.$http.put(`/api/management/v1/epg_provider/${  this.$route.params.id  }/refresh`)
                refreshPromise.then(function() {
                    thisIns.$printSuccess('EPG Provider refresh started')
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
